import React from "react";
import Rotas from './Rotas';
import { pdfjs } from 'react-pdf';
import api from "./requests_api";
import Cookies from "js-cookie";




import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/style/Documentos/Documentos.css';
import '../src/style/InfoNoticia/InfoNoticia.css'
import '../src/style/Home/Home.css';
import '../src/style/Calendario/calendario.css';
import '../src/style/css/bootstrap.css';
import '../src/style/Grupos/Grupos.css';
import '../src/style/InfoNoticia/InfoNoticia.css'
import '../src/style/Login/login.css'
import '../src/style/Menu/Menu.css'
import '../src/style/Noticias/noticias.css';
import '../src/style/Perfil/Perfil.css';
import '../src/style/ViewNotificacoes/ViewNotificacoes.css';
import '../src/style/Mensagens/Mensagens.css';
import '../src/style/Boletins/boletins.css';
import '../src/style/assets/dist/css/bootstrap.min.css';
import '../src/style/css-ascom/ASCOM.css';
import '../src/style/acessonegado/acessonegado.css';
import '../src/style/podcast/podcast.css';
import '../src/style/Comunicados/Comunicados.css';
import '../src/style/SaudeVoce/SaudeVoce.css';
import '../src/style/Apresentacao/apresentacao.css';
import '../src/style/InteligenciaEmSaude/InteligenciaEmSaude.css';
import '../src/style/Tabela/tabela.css';
import '../src/style/Memorial/Memorial.css';
import '../src/style/Wiki/wiki.css'
import '../src/style/SalaReuniao/salareuniao.css'
import '../src/style/ModalEdicao/ModalEdicao.css';
import '../src/style/ModalCadastro/ModalCadastro.css';
import '../src/style/TabNet/tabnet.css';
import '../src/style/Ramais/ramais.css'
import '../src/style/Page404/page404.css'

import '../src/style/_iOS/ios.css'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iOS: "",
      csrf: "",
      username: "",
      name_user: "",
      password: "",
      error: false,
      reset: false,
      termo: false,
      isAuthenticated: false,
      errorReset: false,
      ip: `${process.env.REACT_APP_HOST}`,
      framePod: "",
      fotoUser: "",
      tipoUser: "",
      View_auth: false,
      minha_unidade: "",
      update_info: false,
      info_busca_users_dados: {},
      update_info_form_erro: {},
      telefone_info_view: false,
      telefone_info: {},
      telefone_uni_dep: true,
      view_ramal: false
    };
    this.getSession = this.getSession.bind(this);
    this.InfoUser = this.InfoUser.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.Open_Or_Close_PD = this.Open_Or_Close_PD.bind(this);
    this.getcsrf = this.getcsrf.bind(this);
    this.resetF = this.resetF.bind(this);
    this.StartPodcast = this.StartPodcast.bind(this);
    this.Aceita_termo = this.Aceita_termo.bind(this);
    this.buscaAuthViewMenu = this.buscaAuthViewMenu.bind(this);
    this.aoFechar = this.aoFechar.bind(this);
    this.aoFecharTelefone = this.aoFecharTelefone.bind(this);
    this.Up_Info_User = this.Up_Info_User.bind(this);
    this.TelefoneSearch = this.TelefoneSearch.bind(this);
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.InfoUser();
      this.getcsrf();
      this.buscaAuthViewMenu();
  }
    this.getSession();
    const userAgent = window.navigator.userAgent;
    const iOS = userAgent.match(/iPhone|iPad|iPod/g) || '';
    this.setState({ iOS: iOS })

    pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";

  }

  buscaAuthViewMenu() {
    api.get('/auth_view/menu').then((res) => {
      let res_json = JSON.parse(res.data)
      this.setState({ View_auth: res_json.View_auth })
    }).catch((err) => {
      console.log(err);
    });
  }

  aoFechar() {
    this.setState({ update_info: false })
  };

  aoFecharTelefone() {
    this.setState({ telefone_info_view: false })
  }

  Aceita_termo(aceita) {
    var formdata = new FormData();
    formdata.append('csrfmiddlewaretoken', this.state.csrf)
    formdata.append('termo', aceita)
    api.post("/termo/", formdata)
      .then((res) => {
        this.setState({ termo: res.data.Sucesso })
      })
      .catch((err) => {
        console.log(err);
        this.setState({ salvo: false });
      });
  }

  Up_Info_User(e) {
    e.preventDefault()
    const formCadastro = e.target;
    var formdata = new FormData(formCadastro);
    formdata.append('csrfmiddlewaretoken', this.state.csrf)
    if (!this.state.telefone_uni_dep) {
      api.post("/up/info/user/", formdata)
        .then((res) => {
          let res_json = JSON.parse(res.data)
          if (res_json.sucesso) {
            this.setState({ salvo: true, update_info: false, telefone_info: {} });
          }

        }).catch((errors) => {
          let erro_json = JSON.parse(errors.response.data)
          this.setState({ salvo: false, update_info_form_erro: erro_json.form, telefone_info: {} });
        });
    } else {
      this.setState({ salvo: false, update_info_form_erro: { "telefone": "telefone inválido" }, telefone_info: {} });
    }
  }

  getcsrf() {
    api.get("/csrf/").then((res) => {
      let csrfToken = res.headers.get("X-CSRFToken");
      Cookies.set('csrftoken', csrfToken)
      this.setState({ csrf: csrfToken });
    }).catch((err) => {
      console.log(err);
    });
  }

  getSession() {
    api.get("/session/")
      .then((res) => {
        if (res.data.isAuthenticated) {
          this.setState({ isAuthenticated: true, reset: res.data.reset, termo: res.data.termo_uso });
        }
        else {
          this.setState({ isAuthenticated: false });
          //this.InfoUser();
        }
        this.getcsrf();
      }).catch((err) => {
        console.log(err);
      });
  }

  async InfoUser() {
    await api.get("/InfoUser/")
      .then((res) => {
        this.setState({ username: res.data.username, name_user: res.data.nameuser, fotoUser: res.data.foto, tipoUser: res.data.tipoUser, minha_unidade: res.data.minha_unidade, update_info: res.data.update_info, info_busca_users_dados: JSON.parse(res.data.info_busca_users_dados)[0], view_ramal: res.data.view_ramal });
        if (JSON.parse(res.data.info_busca_users_dados)[0].telefone) {
          this.TelefoneSearch(JSON.parse(res.data.info_busca_users_dados)[0].telefone)
        }
      }).catch((err) => {
        console.log(err);
      });
  }

  async TelefoneSearch(telefone) {
    let url = `/busca/telefone?telefone=${telefone}`
    this.setState({ update_info_form_erro: {} });
    await api.get(url)
      .then((res) => {
        let res_json = JSON.parse(res.data)

        this.setState({ telefone_info_view: res_json.telefone_view, telefone_info: res_json.ramal_info, telefone_uni_dep: res_json.telefone_view });
      }).catch((err) => {
        console.log(err);

      });
  }



  async login(username, password) {
    let formdata = new FormData()
    formdata.append('csrfmiddlewaretoken', this.state.csrf)
    formdata.append('username', username)
    formdata.append('password', password)
    await api.post("/login/", formdata)
      .then((res) => {
        if (res.data.Sucesso) {
          this.setState({
            username: res.data.username, name_user: res.data.nameuser, fotoUser: res.data.foto,
            password: "", error: "", reset: res.data.reset, termo: res.data.termo_uso, tipoUser: res.data.tipoUser
          });
          this.buscaAuthViewMenu()
          this.InfoUser()
        }
        else {
          this.setState({ error: true });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: true });
      });
  }

  logout() {
    api.get("/logout/")
      .then((res) => {
        if (res.status === 200) {
          this.setState({ isAuthenticated: false, reset: false });
          this.getcsrf();
          window.location.replace("/")
        }
      }).catch((err) => {
        console.log(err);
      });
  };

  resetF(event, password1, password2) {
    event.preventDefault();
    var formdata = new FormData()
    formdata.append('csrfmiddlewaretoken', this.state.csrf)
    formdata.append('password1', password1)
    formdata.append('password2', password2)
    api.post("/reset/", formdata)
      .then((res) => {
        if (res.data.Sucesso) {
          this.setState({ reset: false, errorReset: false })
          window.location.replace("/acessar")
        }
        else {
          let listaQ = JSON.parse(res.data)
          this.setState({ errorReset: listaQ })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  Open_Or_Close_PD() {
    document.querySelector("#Podcast_Open_Close_PD").classList.toggle("Podcast--fecharPod")
    document.querySelector("#Podcast_id").innerHTML = ""
  }

  StartPodcast(frame) {
    let div = document.querySelector("#Podcast_Open_Close_PD")
    div.removeAttribute("class")
    div.setAttribute("class", "Podcast")
    document.querySelector("#Podcast_id").innerHTML = frame
  }

  render() {
    return (
      <Rotas cadastro={this.cadastro}
        session={this.getSession}
        ip={this.state.ip}
        logout={this.logout}
        logar={this.login}
        error={this.state.error}
        csrf={this.state.csrf} username={this.state.username}
        name_user={this.state.name_user}
        isAuthenticated={this.state.isAuthenticated}
        reset={this.state.reset} resetF={this.resetF}
        errorReset={this.state.errorReset}
        Open_Or_Close_PD={this.Open_Or_Close_PD}
        StartPodcast={this.StartPodcast}
        tipoUser={this.state.tipoUser}
        fotoUser={this.state.fotoUser}
        iOS={this.state.iOS}
        termo={this.state.termo} Aceita_termo={this.Aceita_termo}
        View_auth={this.state.View_auth}
        minha_unidade={this.state.minha_unidade}
        update_info={this.state.update_info}
        aoFechar={this.aoFechar}
        info_busca_users_dados={this.state.info_busca_users_dados}
        Up_Info_User={this.Up_Info_User}
        update_info_form_erro={this.state.update_info_form_erro}
        telefone_info_view={this.state.telefone_info_view}
        telefone_info={this.state.telefone_info}
        aoFecharTelefone={this.aoFecharTelefone}
        TelefoneSearch={this.TelefoneSearch}
        telefone_uni_dep={this.state.telefone_uni_dep}
        view_ramal={this.state.view_ramal}
      />
    )
  }
}

export default App;
